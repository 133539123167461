import { useEffect, useState } from 'react';
import * as NfaLogger from '@netflix/nfa-logger/lib';
import { useRouter } from 'next/router';
import { TicketTypeString } from 'types';
import { FormikErrors } from 'formik';
import { captureException } from '@sentry/react';

type FormSubmission =
  | TicketTypeString
  | 'MY_ACCOUNT'
  | 'REGISTRATION'
  | 'EMERGENCY_PENDING';

type RegistrationResponse =
  | 'APPROVED'
  | 'PENDING'
  | 'DENIED'
  | 'DUPLICATE'
  | 'ERROR';

export const useAnalytics = () => {
  const [nfa, setNfa] = useState<typeof NfaLogger | null>(null);
  const router = useRouter();

  useEffect(() => {
    /**
     * Netflix App Analytics Web Logger.
     * Needs to be dynamically imported because this component is rendered
     * server side and the `nfa` library references the browser `window`.
     * */
    import('@netflix/nfa-logger/lib/helper').then((mod) => {
      setNfa(mod.nfa);
    });
  }, []);

  function getPageInfo() {
    return {
      source: router.pathname,
    };
  }

  // Global events
  const logNavigationClick = (linkTitle: string) => {
    nfa?.logFeatureEngaged('header_navigation_click', linkTitle, getPageInfo());
  };

  const logLanguageSelection = (lang: string) => {
    nfa?.logFeatureEngaged('header_language_selected', lang, getPageInfo());
  };

  const logNavigationButtonClick = (href: string) => {
    nfa?.logFeatureEngaged('navigation_button_click', href, getPageInfo());
  };

  const logBackButtonClick = () => {
    nfa?.logFeatureEngaged('back_button_click', '', getPageInfo());
  };

  const logAccordionClick = (title: string, type: string) => {
    nfa?.logFeatureEngaged('accordion_click', title, {
      type: type,
      ...getPageInfo(),
    });
  };

  const logException = (error: Error) => {
    captureException(error);
    nfa?.logExceptionOccurred(error, getPageInfo());
  };

  // Homepage and Sign Up
  const logSignInClick = () => {
    nfa?.logFeatureEngaged('sign_in_click', 'click', getPageInfo());
  };

  const logContactUsClick = () => {
    nfa?.logFeatureEngaged('contact_us_click', 'click', getPageInfo());
  };

  const logRegistrationEvent = (resp: RegistrationResponse) => {
    nfa?.logFeatureEngaged('registration_event', resp, getPageInfo());
  };

  // Form submission flow
  const logRepeaterButtonAdd = (name: string) => {
    nfa?.logFeatureEngaged('repeater_button_click', 'add', {
      name: name,
      ...getPageInfo(),
    });
  };

  const logRepeaterButtonRemove = (name: string) => {
    nfa?.logFeatureEngaged('repeater_button_click', 'remove', {
      name: name,
      ...getPageInfo(),
    });
  };

  const logValidateButtonClick = (ticketType: FormSubmission) => {
    nfa?.logFeatureEngaged(
      'ticket_validate_button_click',
      ticketType,
      getPageInfo()
    );
  };

  const logEditButtonClick = (ticketType: FormSubmission) => {
    nfa?.logFeatureEngaged(
      'ticket_edit_button_click',
      ticketType,
      getPageInfo()
    );
  };

  const logSubmitButtonClick = (ticketType: FormSubmission) => {
    nfa?.logFeatureEngaged(
      'ticket_submit_button_click',
      ticketType,
      getPageInfo()
    );
  };

  const logFileUpload = () => {
    nfa?.logFeatureEngaged('file_upload', 'add', getPageInfo());
  };

  const logFileUploadRemove = () => {
    nfa?.logFeatureEngaged('file_upload', 'remove', getPageInfo());
  };

  const logFileUploadException = (error: Error) => {
    captureException(error);
    nfa?.logExceptionOccurred(error, getPageInfo());
  };

  const logValidationErrors = (
    errors: FormikErrors<unknown>,
    ticketType: FormSubmission
  ) => {
    nfa?.logFeatureEngaged('validation_errors', ticketType, {
      errors: JSON.stringify(errors),
      ...getPageInfo(),
    });
  };

  const startFormDuration = (ticketType: FormSubmission) => {
    nfa?.startDuration(ticketType);
  };

  const endFormDuration = (ticketType: FormSubmission) => {
    nfa?.endDuration(ticketType);
  };

  const logMaintenancePageShown = () => {
    nfa?.logFeatureEngaged('page_view', 'maintenance', getPageInfo());
  };

  return {
    logNavigationClick,
    logLanguageSelection,
    logNavigationButtonClick,
    logBackButtonClick,
    logAccordionClick,
    logException,
    logRepeaterButtonAdd,
    logRepeaterButtonRemove,
    logEditButtonClick,
    logValidateButtonClick,
    logSubmitButtonClick,
    logFileUpload,
    logFileUploadException,
    logFileUploadRemove,
    logValidationErrors,
    startFormDuration,
    endFormDuration,
    logSignInClick,
    logContactUsClick,
    logRegistrationEvent,
    logMaintenancePageShown,
  };
};
